
import Routes from "@/Routes";
import { Component, Vue, Watch } from "vue-property-decorator";
import { RouteConfig } from "vue-router";
import AuthStore from "@/store/AuthStore";
import AuthenticateRequest from "@/models/AuthenticateRequest";
import NotificationStore from "@/store/NotificationStore";
import SnackbarNotification, { SnackbarNotificationType } from "@/models/SnackbarNotification";
import AppStore from "@/store/AppStore";
import IAppTheme from '@/../../Main/src/themes/IAppTheme';

@Component({
  components: {
  },
})
export default class Signin extends Vue {
  msalLoginInProgress = false;
  passwordLoginInProgress = false;
  googleLoginInProgress = false;
  passwordVisible = false;
  model: any = {
    email: "",
    password: "",
  };

  get rules(): { [key: string]: any; } {
    return {
      required: (v: any) => 
        !!v || 
        this.$t('generic.validation.required'),
      email: (v: any) => 
        /.+@.+/.test(v) || 
        this.$t('generic.validation.validEmail')
    }
  }

  get isAdminSigninPage() {
    return this.$route.query.role && this.$route.query.role === "admin";
    // return process.env.NODE_ENV === "development"
    //   ? true
    //   : process.env.NODE_ENV === "production" && 
  }

  get useInvitationCodes(): boolean {
    return AppStore.features?.invitationCodes 
      ? true 
      : false;
  }

  get useOrganizationAccessCodes(): boolean {
    return AppStore.features?.organizationAccessCodes?.enabled
      ? true 
      : false;
  }

  get forgotPasswordRoute(): RouteConfig {
    return Routes.forgotPasswordRoute;
  }

  get appTheme(): IAppTheme {
    return AppStore.appTheme
  }

  get appThemeDark(): boolean {
    return this.appTheme.dark;
  }

  get appThemes(): IAppTheme[] {
    return AppStore.appThemes
  }

  get appName() {
    return this.appTheme?.appName ? this.appTheme?.appName : process.env.VUE_APP_NAME
  }

  async onSigninClick() {
    if (!(this.$refs.signinForm as any).validate()) {
      return;
    }
    this.passwordLoginInProgress = true;

    try {
      const signInRequest = new AuthenticateRequest(this.model.email, this.model.password)
      await AuthStore.signIn(signInRequest);
      this.passwordLoginInProgress = false;
    } catch (e) {
      console.error(e);
      const message = this.$t('views.signin.form.submit.error.invalidCredentials.notification').toString();
      NotificationStore.addSnackbarNotification(new SnackbarNotification(SnackbarNotificationType.Error, message));
      this.passwordLoginInProgress = false;
    }
  }

  async onSigninWithMsalClick() {
    this.msalLoginInProgress = true;

    try {
      await this.$msal.signIn();
      this.msalLoginInProgress = false;
    } catch (e) {
      const message = this.$t('views.signin.form.submit.error.externalLogin.notification').toString();
      NotificationStore.addSnackbarNotification(new SnackbarNotification(SnackbarNotificationType.Error, message));
      this.msalLoginInProgress = false;
    }
  }

  async onSigninWithGoogleClick() {
    this.googleLoginInProgress = true;
    try {
      await this.$firebaseAuth.signInWithGoogle();
    } catch (e) {
      const message = this.$t('views.signin.form.submit.error.externalLogin.notification').toString();
      NotificationStore.addSnackbarNotification(new SnackbarNotification(SnackbarNotificationType.Error, message));
    }

    this.googleLoginInProgress = false;
  }

  onRegisterClick() {
    this.$router.push(Routes.signup);
  }
  onRegisterWithOrganizationAccessCodeClick() {
    this.$router.push(Routes.access);
  }
}
